import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getHello: build.query<GetHelloApiResponse, GetHelloApiArg>({
      query: () => ({ url: `/hello` }),
    }),
    getUserName: build.query<GetUserNameApiResponse, GetUserNameApiArg>({
      query: () => ({ url: `/user/name` }),
    }),
    postUserName: build.mutation<PostUserNameApiResponse, PostUserNameApiArg>({
      query: (queryArg) => ({
        url: `/user/name`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type GetHelloApiResponse = /** status 200  */ {
  hello?: string;
};
export type GetHelloApiArg = void;
export type GetUserNameApiResponse = /** status 200  */ {
  name?: string;
};
export type GetUserNameApiArg = void;
export type PostUserNameApiResponse = unknown;
export type PostUserNameApiArg = {
  body: {
    name?: string;
  };
};
export const {
  useGetHelloQuery,
  useGetUserNameQuery,
  usePostUserNameMutation,
} = injectedRtkApi;
