import React, {useEffect} from 'react';
import './App.css';
import {useGetUserNameQuery, usePostUserNameMutation} from "./store/api";
import {Button, Form, Input, message} from "antd";
import {useForm} from "antd/lib/form/Form";

function App() {
    const {data: userNameData, refetch: userNameRefetch} = useGetUserNameQuery()
    const [trigger, result] = usePostUserNameMutation()
    const [form1] = useForm()
    const [form2] = useForm()

    useEffect(() => {
        if (result.isSuccess) {
            message.success("提交姓名成功").then()
            userNameRefetch()
        }
        if (result.isError) {
            message.error("提交姓名失败").then()
        }
    }, [result, userNameRefetch])

    useEffect(() => {
        form1.setFieldValue("name", userNameData?.name)
    }, [form1, userNameData])

    return (
        <div>
            <Form labelCol={{span: 4}} wrapperCol={{span: 12}} form={form1}>
                <Form.Item name={"name"} label={"姓名"}>
                    <Input disabled/>
                </Form.Item>
            </Form>

            <Form labelCol={{span: 4}} wrapperCol={{span: 12}} form={form2}>
                <Form.Item name={"name"} label={"姓名"}>
                    <Input/>
                </Form.Item>
                <Form.Item wrapperCol={{offset: 4, span: 12}}>
                    <Button onClick={() => trigger({body: form2.getFieldsValue()})}>
                        提交
                    </Button>
                </Form.Item>
            </Form>

        </div>
    );
}

export default App;
