import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Provider} from "react-redux";
import {store} from "./store/store";
import 'antd/dist/antd.css';

// if (process.env.NODE_ENV === 'development') {
//     const {worker} = require('./mock')
//     worker.start().then(() => console.log("worker started"))
// }

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>
);

